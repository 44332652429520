import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

import i18n from './config';
import { PageContextProvider } from './page-context';

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  i18next.changeLanguage(props.pageContext.lang);

  return <PageContextProvider pageContext={props.pageContext}>{element}</PageContextProvider>;
};
