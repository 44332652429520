import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next.use(LanguageDetector).init({
  fallbackLng: 'en',
  resources: {
    zh: {
      footer: require('../locales/zh/footer.json'),
      translation: require('../locales/zh/translation.json'),
    },
    en: {
      footer: require('../locales/en/footer.json'),
      translation: require('../locales/en/translation.json'),
    }
  },
  ns: ['translation', 'footer'],
  defaultNS: 'translation',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
});

i18next.languages = ['zh', 'en'];

export default i18next;
